import logo from './logo.svg';
import './App.css';
import Form from "./form/Form";


function App() {
  return (
      <>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap"/>
        <Form />
      </>

  );
}

export default App;
